import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import pl from '../translation/pl.json'
import en from '../translation/en.json'

i18next.use(LanguageDetector).init({
  debug: true,
  lng: localStorage.getItem('lang') || 'pl',
  detection: {
    order: ['localStorage', 'cookie', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
  },
  fallbackLng: 'pl',
  resources: {
    pl: { translation: pl },
    en: { translation: en },
  },
});

export const trans = (key: string, htmlElement?: string) =>
    i18next.t(key, { htmlElement: htmlElement, interpolation: { escapeValue: false } });

export default i18next;
