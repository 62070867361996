import React, {useState} from 'react';
import './ProductInfoView.css';
import {useNavigate, useParams} from 'react-router-dom';
import {useProductData} from '../../hooks/useProductData';
import Loader from "../../components/atoms/Loader";
import {trans} from "../../service/TranslationService";

function ProductInfoView() {
    const navigate = useNavigate();
    const {productId} = useParams();
    const {productData, loading, error} = useProductData(productId);
    const [expandedRows, setExpandedRows] = useState(new Set());

    const fieldsToHide = ['name', 'image', '_id', 'parentNode', 'path', 'nodeType', 'nodePermissions', 'events', 'children', 'localization', 'productLifeCycle']
    const producerFieldsToHide = ['_id', 'parentNode', 'path', 'nodeType', 'nodePermissions', 'events', 'children', 'localization', 'productLifeCycle', 'name', 'companyName', 'entityRole']

    const toggleRow = (id) => {
        setExpandedRows((prev) => {
            const newExpandedRows = new Set(prev);
            if (newExpandedRows.has(id)) {
                newExpandedRows.delete(id);
            } else {
                newExpandedRows.add(id);
            }
            return newExpandedRows;
        });
    };

    const handleBack = () => {
        navigate(`/product-scan-main/${productId}`);
    };

    const renderRows = () => {
        if (!productData || typeof productData !== 'object') {
            return null;
        }

        return Object.entries(productData).map(([key, value]) => {

            if (fieldsToHide.includes(key)) {
                return null
            }

            switch (key) {
                case 'producerData':
                    return (
                        <React.Fragment key={key}>
                            <tr
                                onClick={() => toggleRow(key)}
                                className={expandedRows.has(key) ? 'expanded-header' : ''}
                            >
                                <td><strong>{trans('productView.manufacturer')}:</strong></td>
                                <td>{value['name'] || trans('productView.noData')}</td>
                            </tr>
                            {expandedRows.has(key) && Object.entries(value).map(([subKey, subValue]) => producerFieldsToHide.includes(subKey) ? null : (
                                <tr key={`${key}-${subKey}`} className="sub-table-data">
                                    <td><strong>{trans(`productView.${subKey}`)}:</strong></td>
                                    <td>{subValue || trans('productView.noData')}</td>
                                </tr>))}
                        </React.Fragment>
                    );
                case 'instructions':
                    return (
                        <tr key={key}>
                            <td><strong>{trans(`productView.${key}`)}:</strong></td>
                            <td>
                                {value ? (
                                    <button
                                        onClick={() => window.open(value as string | URL, '_blank')}
                                        className='view-button'
                                    >
                                        {trans('productView.view')}
                                    </button>
                                ) : (
                                    trans('productView.noData')
                                )}
                            </td>
                        </tr>
                    );

                default:
                    return (
                        <tr key={key}>
                            <td><strong>{trans(`productView.${key}`)}:</strong></td>
                            <td>
                                {typeof value === 'boolean' ? (
                                    <span style={{
                                        color: value ? 'green' : 'red', fontWeight: 'bold'
                                    }}>{value ? trans('productView.yes') : trans('productView.no')}</span>
                                ) : (value as string || trans('productView.noData'))}
                            </td>
                        </tr>
                    );
            }
        });
    };

    if (loading) {
        return <Loader message='Ładowanie danych, proszę czekać...'/>
    }

    if (error) {
        return (
            <div className="product-container">
                <button type="button" className="back-button" onClick={handleBack}>
                    {trans('productView.back')}
                </button>
                <div>{trans('productView.error')}: {error}</div>
            </div>
        );
    }

    return (<div className="product-container">
        <button type="button" className="back-button" onClick={handleBack}>
            {trans('productView.back')}
        </button>
        <h1 className="product-title">{trans('productView.productInfo')}</h1>
        <div className="table-container" style={{overflowX: 'auto'}}>
            <table className="product-table">
                <thead>
                <tr>
                    <th>{trans('productView.category')}</th>
                    <th>{trans('productView.details')}</th>
                </tr>
                </thead>
                <tbody>
                {renderRows()}
                </tbody>
            </table>
        </div>
    </div>);
}

export default ProductInfoView;
