import React from 'react';
import './ProductMapView.css';
import {useNavigate, useParams} from "react-router-dom";
import {MapContainer, Marker, Polyline, Popup, TileLayer} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import Loader from "../../components/atoms/Loader";
import {trans} from "../../service/TranslationService";
import {useProductData} from "../../hooks/useProductData";

function ProductMapView() {
    const navigate = useNavigate();
    const {productId} = useParams();
    const {productData, loading, error} = useProductData(productId);

    const calculateTotalDistance = (coordinates: [number, number][]) => {
        if (coordinates.length < 2) return 0

        const toRadians = (deg: number) => (deg * Math.PI) / 180

        let totalDistance = 0
        for (let i = 0; i < coordinates.length - 1; i++) {
            const [lat1, lon1] = coordinates[i]
            const [lat2, lon2] = coordinates[i + 1]

            const R = 6371
            const dLat = toRadians(lat2 - lat1)
            const dLon = toRadians(lon2 - lon1)

            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(toRadians(lat1)) *
                Math.cos(toRadians(lat2)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2)

            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
            totalDistance += R * c
        }

        return totalDistance.toFixed(2)
    }

    const customMarker = new L.Icon({
        iconUrl: markerIcon,
        iconRetinaUrl: markerIcon2x,
        shadowUrl: markerShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    const handleBack = () => {
        navigate('/product-scan-main/' + productId);
    };

    if (loading) return <Loader message="Ładowanie danych, proszę czekać..."/>;
    if (error) return <div>Błąd ładowania danych</div>;

    const locations = productData?.localization || [];
    const totalDistance = calculateTotalDistance(locations)

    return !locations.length ? (
        <div>Brak dostępnych lokalizacji</div>
    ) : (
        <div className="product-container">
            <button type="button" className="back-button" onClick={handleBack}>Wróć</button>
            <h1 className="product-title">{productData.productName}</h1>
            <div style={{width: '90%', margin: '0 auto'}}>
                <MapContainer
                    style={{height: '200px', width: '100%'}}
                    center={locations[locations.length - 1]}
                    zoom={13}
                    scrollWheelZoom={false}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {locations.map((pos, index) => (
                        <Marker key={index} position={pos} icon={customMarker}>
                            <Popup>{index === 0 ? 'Początek' : `Punkt ${index + 1}`}</Popup>
                        </Marker>
                    ))}
                    {locations.length > 1 && <Polyline positions={locations} color="#06b6d4"/>}
                </MapContainer>
            </div>
            <div className="product-label">
                <strong>Łączny ślad węglowy:</strong> {productData?.carbonFootprint || trans('productView.noData')}
            </div>
            <div className="product-label">
                <strong>Przebyty dystans:</strong> {`${totalDistance} km` || trans('productView.noData')}
            </div>
        </div>
    );
}

export default ProductMapView;