import {BrowserRouter, Navigate, Route, Routes, useParams} from 'react-router-dom';
import MainTemplate from '../components/MainTemplate';
import MainNavigation from '../components/molecules/mainNavigation/MainNavigation';
import {RouteType} from '../model/Model';
import React, {useRef} from 'react';
import {Helmet} from 'react-helmet';
import Footer from '../components/molecules/footer/Footer';
import {ArrowNav} from '../components/molecules/arrowNav/ArrowNav';
import PrivacyPolicy from './PrivacyPolicy';
import {Homepage} from './Homepage';
import {AboutUs} from './AboutUs';
import 'typeface-montserrat';
import ProductMainView from "./ProductMainView/ProductMainView";
import ProductInfoView from "./ProductInfoView/ProductInfoView";
import ProductMapView from "./ProductMapView/ProductMapView";

interface Props {
    content?: JSX.Element;
    route: RouteType;
}

const PageSelector: React.FC<Props> = ({route}) => {
    const upperRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);
    const {productId} = useParams();

    console.log('productId', productId);
    return (
        <>
            <div ref={upperRef}></div>
            {!productId && <MainNavigation/>}
            <main>
                {
                    {
                        [RouteType.HOMEPAGE]: <Homepage footerRef={footerRef}/>,
                    }[route]
                }
            </main>
            <main>
                {
                    {
                        [RouteType.ABOUT_US]: <AboutUs footerRef={footerRef}/>,
                    }[route]
                }
            </main>
            <main>
                {
                    {
                        [RouteType.PRIVACY_POLICY]: <PrivacyPolicy/>,
                    }[route]
                }
            </main>
            <main>
                {
                    {
                        [RouteType.PRODUCT_SCAN_VIEW_MAIN]: <ProductMainView/>,
                    }[route]
                }
            </main>
            <main>
                {
                    {
                        [RouteType.PRODUCT_SCAN_VIEW_INFO]: <ProductInfoView/>,
                    }[route]
                }
            </main>
            <main>
                {
                    {
                        [RouteType.PRODUCT_SCAN_VIEW_MAP]: <ProductMapView/>,
                    }[route]
                }
            </main>


            {!productId && (
                <>
                    {' '}
                    <ArrowNav upperRef={upperRef}/>{' '}
                    <div ref={footerRef}>
                        <Footer/>
                    </div>
                </>
            )}
        </>
    );
};

const App: React.FC = () => {
    return (
        <MainTemplate>
            <Helmet>
                <title>Axelote</title>
            </Helmet>
            <BrowserRouter>
                <Routes>
                    {(Object.values(RouteType) as RouteType[]).map((route) => {
                        return <Route key={route} path={'/' + route} element={<PageSelector route={route}/>}/>;
                    })}
                    <Route path="*" element={<Navigate to="/"/>}/>
                </Routes>
            </BrowserRouter>
        </MainTemplate>
    );
};

export default App;
