import { useEffect, useState } from 'react';
import {fetchProductData} from "../service/apiService";

export const useProductData = (productId) => {
    const [productData, setProductData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                const data = await fetchProductData(productId);
                setProductData(data);
            } catch (err) {
                setError(err['message']);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [productId]);

    return { productData, loading, error };
};