const BASE_URL = 'https://cr-api-dev.axelote.com';

/**
 * Wysyła zapytanie do podanego endpointa
 * @param {string} endpoint - Endpoint, np. `/dpp-demo-get-product-data/:id`
 * @param {Object} options - Opcje dla fetch, np. metoda, body, itp.
 * @returns {Promise<any>} - Zwraca dane w formacie JSON
 */
export const fetchApi = async (endpoint, options = {}) => {
    try {
        const response = await fetch(`${BASE_URL}${endpoint}`, options);

        if (!response.ok) {
            throw new Error(`Błąd: ${response.status} - ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error(`Błąd podczas pobierania danych z ${endpoint}:`, error);
        throw error;
    }
};

/**
 * Pobiera dane produktu
 * @param {string} productId - ID produktu
 * @returns {Promise<any>} - Dane produktu
 */
export const fetchProductData = async (productId) => {
    const data = await fetchApi(`/dpp-demo-get-product-data/${productId}`);

    if (data?.image) {
        const imageData = await fetchApi(`/get-file/${data.image}`);
        data.image = imageData.data
            ? `data:${imageData.mimetype};base64,${imageData.data}`
            : null;
    }

    if (data?.instructions) {
        const instructionsData = await fetchApi(`/get-file/${data.instructions}`);
        const blob = new Blob([Uint8Array.from(atob(instructionsData.data), c => c.charCodeAt(0))], { type: 'application/pdf' });
        data.instructions = URL.createObjectURL(blob);
    }

    return data;
};

/**
 * Pobiera plik na podstawie ID
 * @param {string} fileId - ID pliku
 * @returns {Promise<any>} - Dane pliku
 */
export const fetchFile = async (fileId) => {
    return await fetchApi(`/get-file/${fileId}`);
};
