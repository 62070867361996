import React from 'react';
import './ProductMainView.css';
import {useNavigate, useParams} from 'react-router-dom';
import Loader from "../../components/atoms/Loader";
import {trans} from "../../service/TranslationService";
import {useProductData} from "../../hooks/useProductData";

function ProductMainView() {
    const {productId} = useParams();
    const navigate = useNavigate();
    const {productData, loading, error} = useProductData(productId);

    const handleBack = () => {
        navigate('/home');
    };

    const handleScanInfo = () => {
        navigate('/product-scan-info/' + productId);
    };

    const handleScanMap = () => {
        navigate('/product-scan-map/' + productId);
    };

    if (loading) return <Loader message='Ładowanie danych, proszę czekać...'/>;

    if (error) {
        return (
            <div className="product-container">
                <button type="button" className="back-button" onClick={handleBack}>
                    {trans('productView.back')}
                </button>
                <div>{trans('productView.error')}: {error}</div>
            </div>
        );
    }

    return (
        <div className="product-container">
            <h1 className="product-title">{productData.productName || productData.name}</h1>
            <img
                className="product-image"
                src={productData.image || 'placeholder.jpg'}
                alt="Zdjęcie produktu"
            />
            <button className="action-button" onClick={handleScanInfo}>Sprawdź produkt</button>
            <button className="action-button" onClick={handleScanMap}>Ślad węglowy</button>
        </div>
    );
}

export default ProductMainView;