import React from 'react';
import styled, { keyframes } from 'styled-components';

interface LoaderProps {
    message?: string;
}

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 1000;
`;

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #007bff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  font-size: 1.2rem;
  color: #555;
  font-weight: bold;
`;

const Loader: React.FC<LoaderProps> = ({ message = '' }) => {
    return (
        <LoadingContainer>
            <Spinner />
            {message && <Message>{message}</Message>}
        </LoadingContainer>
    );
};

export default Loader;